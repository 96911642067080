<template>
  <div class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this folder?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div v-if="folder" class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Type</label>
          <select v-model="folder.type">
            <option v-for="type in folderTypes" :key="type" :value="type">
              {{ type.toUpperCase() }}
            </option>
          </select>
          <label class="text-left">URL</label>
          <div v-if="folder.harddrive" class="text-left">
            {{ folder.harddrive.name }}/{{ folder.project.client.name }}/{{
              folder.project.title
            }}
            {{ folder.project.subtitle }}
          </div>
          <div v-if="!folder.harddrive" class="text-left">Invalid Drive</div>
          <label class="text-left">Description</label>
          <textarea v-model="folder.description" />
          <div class="h-stack h-space-between">
            <button @click="removeDialog()" class="edit">Delete</button>
            <button @click="submit()" class="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/constants.js";
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      folderTypes: constants.folderTypes,
      folder: null,
    };
  },
  methods: {
    ...mapActions(["editFolder", "getFolder", "removeFolder", "getDrives"]),
    submit() {
      this.editFolder({
        _id: this.folder._id,
        type: this.folder.type,
        description: this.folder.description,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.removeFolder(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dismiss();
    },
  },
  mounted() {
    this.getFolder(this.id)
      .then((folder) => {
        this.folder = folder;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>